import { combineReducers } from "redux";
import cmsReducer from "./cms";
import configReducer from "./config";

const rootReducer = combineReducers({
    cms: cmsReducer,
    config: configReducer,
});

export default rootReducer;
