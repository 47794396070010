import React, { Component } from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import Scroller from "./Scroller";
import Navbar from "./navbar/Navbar";
import {
    ROUTE_CLUBHOUSE,
    ROUTE_WORKSHOP,
    ROUTE_FAQ,
    ROUTE_ABOUT,
    ROUTE_HOME,
    ROUTE_NOT_FOUND,
    ROUTE_WILDCARD,
} from "../constants/routes";
import HomePage from "../pages/home/HomePage";
import AboutPage from "../pages/about/AboutPage";
import NotFoundPage from "../pages/notfound/NotFoundPage";
import WorkshopPage from "../pages/workshop/WorkshopPage";
import Footer from "./footer/Footer";
import FaqPage from "../pages/faq/FaqPage";
import { compose } from "redux";
import { connect } from "react-redux";
// import semverSatisfies from "semver/functions/satisfies";
// import { HOSTNAME_LIVE } from "../constants/business";

class App extends Component {
    // isAppLive = () => {
    //     const appVer = process.env.REACT_APP_VERSION;
    //     const { is_live } = this.props.config;
    //     return (
    //         semverSatisfies(appVer, is_live) ||
    //         window.location.hostname !== HOSTNAME_LIVE
    //     );
    // };

    render() {
        return (
            <Router>
                <Route
                    render={({ location }) => (
                        <div style={{ height: "100%" }}>
                            <Scroller>
                                <Navbar />
                                <TransitionGroup>
                                    <CSSTransition
                                        key={location.key}
                                        timeout={0}
                                    >
                                        <Switch location={location}>
                                            <Route
                                                path={ROUTE_HOME}
                                                exact
                                                component={HomePage}
                                            />
                                            <Route
                                                path={ROUTE_CLUBHOUSE}
                                                exact
                                                component={HomePage}
                                            />
                                            {/*<Route path={ROUTE_CLASS} exact component={ClassPage}/>*/}
                                            <Route
                                                path={ROUTE_WORKSHOP}
                                                component={WorkshopPage}
                                            />
                                            <Route
                                                path={ROUTE_ABOUT}
                                                exact
                                                component={AboutPage}
                                            />
                                            <Route
                                                path={ROUTE_FAQ}
                                                exact
                                                component={FaqPage}
                                            />
                                            {/*TODO <Route path={ROUTE_CONTACT} exact component={ContactPage}/>*/}
                                            <Route
                                                path={ROUTE_NOT_FOUND}
                                                component={NotFoundPage}
                                            />
                                            <Route
                                                path={ROUTE_WILDCARD}
                                                component={NotFoundPage}
                                            />
                                        </Switch>
                                    </CSSTransition>
                                </TransitionGroup>
                                <Footer />
                            </Scroller>
                        </div>
                    )}
                />
            </Router>
        );
    }
}

const mapStoreToProps = ({ config }) => ({ config });

export default compose(connect(mapStoreToProps))(App);
