import { ACTION_SET_CONFIG } from "../constants";

const getInitialState = () => {
    return {};
};

const INITIAL_STATE = getInitialState();

const applySetConfig = (state, action) => ({
    ...state,
    ...action.config,
});

const configReducer = (state = INITIAL_STATE, action) => {
    const handlers = {
        [ACTION_SET_CONFIG]: applySetConfig,
    };
    if (action.type in handlers) {
        return handlers[action.type](state, action);
    } else {
        return state;
    }
};

export default configReducer;
