import React from "react";
import "./Navbar.css";
import { NAV_LINKS, ROUTE_HOME } from "../../constants/routes";
import { Link } from "react-router-dom";

class Navbar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            isScrolled: false,
        };
    }

    toggleStateGenerator = (isOpen) => () => this.setState({ isOpen });

    listenScrollEvent = () => {
        const { isScrolled } = this.state;
        if (window.scrollY > 0 && !isScrolled) {
            this.setState({ isScrolled: true });
        } else if (window.scrollY === 0 && isScrolled) {
            this.setState({ isScrolled: false });
        }
    };

    componentDidMount() {
        window.addEventListener("scroll", this.listenScrollEvent);
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.listenScrollEvent);
    }

    render() {
        const { isOpen, isScrolled } = this.state;
        return (
            <div className={`navbar${isScrolled ? " scrolled" : ""}`}>
                <div className="navbar-left">
                    <Link to={ROUTE_HOME} className="navbar-brand-icon">
                        THE WORKSHOP CLUB
                    </Link>
                </div>
                <div className="navbar-right">
                    <div className="navbar-links-desktop d-none d-lg-flex align-items-center">
                        {NAV_LINKS.filter(
                            ({ isVisibleDesktopNav }) => isVisibleDesktopNav
                        ).map((link) => (
                            <Link key={link.display} to={link.to}>
                                {link.display}
                            </Link>
                        ))}
                    </div>
                    <div
                        id="navbar-toggle"
                        className={`${isOpen ? "expand " : ""}d-lg-none`}
                        onClick={this.toggleStateGenerator(!isOpen)}
                    >
                        <span />
                        <span />
                        <span />
                    </div>
                    <div className={`navmenu${isOpen ? " expand" : ""}`}>
                        {NAV_LINKS.filter(
                            ({ isVisibleNavMenu }) => isVisibleNavMenu
                        ).map((link) => (
                            <Link
                                key={link.display}
                                to={link.to}
                                onClick={this.toggleStateGenerator(false)}
                            >
                                {link.display}
                            </Link>
                        ))}
                    </div>
                </div>
            </div>
        );
    }
}

export default Navbar;
