import React from "react";
import "./HomePage.css";
import Helmet from "react-helmet";
import { APP_DESCRIPTION, APP_TITLE } from "../../constants/helmet";
import { ROUTE_WORKSHOPS } from "../../constants/routes";
import ExternalLink from "../../components/links/ExternalLink";
import { compose } from "redux";
import { connect } from "react-redux";
import { DATA_CATEGORIES } from "../../constants/v1/categories";
import {
  DATA_WORKSHOPS,
  homePageComparator,
} from "../../constants/v1/workshops";
import {
  getFormattedClassStartDate,
  getFormattedClassStartTime,
} from "../../utils/datetime";
import { GetJumboWorkshop } from "../../modules/workshopda/workshopda";
import BookNowButton from "../../components/buttons/BookNowButton";
import { HREF_CLUBHOUSE } from "../../constants/business";

class HomePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    GetJumboWorkshop()
      .then((featuredWorkshop) => this.setState({ featuredWorkshop }))
      .catch(console.error);
  }

  goToWorkshop = (workshop) => () => {
    this.props.history.push(`${ROUTE_WORKSHOPS}/${workshop.id}`);
  };

  getJumboBackgroundImage = () => {
    const { featuredWorkshop } = this.state;
    if (!!featuredWorkshop) {
      return `linear-gradient(0deg, rgba(0,0,0,1), rgba(0,0,0,0.1), rgba(0,0,0,0.1), rgba(0,0,0,0.1), rgba(0,0,0,0.1)), url(${featuredWorkshop.imgFeaturedJumboDesktop})`;
    } else {
      return `linear-gradient(0deg, rgba(0,0,0,1), rgba(255,255,255,0.1), rgba(255,255,255,0.2),rgba(255,255,255,0.4), rgba(255,255,255,0.4))`;
    }
  };

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>{APP_TITLE}</title>
          <meta property="og:title" content={APP_TITLE} />
          <meta name="description" content={APP_DESCRIPTION} />
          <meta property="og:description" content={APP_DESCRIPTION} />
        </Helmet>
        <div
          className="jumbotron hero hero-home"
          style={{
            backgroundImage: this.getJumboBackgroundImage(),
          }}
        >
          <div className="container h-100">
            <div className="row h-100">
              <div className="col-lg-6 d-flex justify-content-center align-items-center flex-column text-center">
                <div className="jumbotron-title">
                  {"LEARN WHAT YOU WANT".toUpperCase()}
                  <br />
                  {"TO DO WHAT YOU LOVE".toUpperCase()}
                </div>
                <div className="w-100">
                  <div className="jumbotron-divider" />
                </div>
                {/*<div className="jumbotron-subtitle"></div>*/}
                <p className="jumbotron-description mb-1">
                  New possibilities, knowledge and connections.
                </p>
                <p className="jumbotron-description">
                  Do something you’ve never done today.
                </p>
                <BookNowButton soldOut={false} />
              </div>
            </div>
          </div>
        </div>
        <section id="featured" className="dark">
          <div className="container-fluid">
            <div className="row">
              {/*TODO: Sort order (most recent first, add sold out field, move sold outs to end)*/}
              {DATA_WORKSHOPS.sort(homePageComparator).map((workshop) => (
                <div
                  key={workshop.id}
                  className="col-lg-3 col-md-4 col-sm-6 col-xs-12"
                >
                  <div
                    className={`home-featured-item${
                      workshop.soldOut ? " sold-out" : ""
                    }`}
                    style={{
                      backgroundImage: `linear-gradient(0deg, rgba(0,0,0,1), rgba(0,0,0,0), rgba(0,0,0,0)), url(${workshop.imgHomePageListing})`,
                    }}
                    onClick={this.goToWorkshop(workshop)}
                  >
                    {workshop.soldOut ? (
                      <div className="home-featured-item-sold-out">
                        SOLD OUT
                      </div>
                    ) : (
                      <div className="home-featured-item-category">
                        {DATA_CATEGORIES[workshop.categoryId].name}
                      </div>
                    )}
                    <div className="p-2">
                      <div className="home-featured-item-title">
                        {workshop.title}
                      </div>
                      {/*
                       *TODO: Animations for hover*/}
                      {/*TODO: Click on different parts to get to instructor page or something*/}
                      <div className="home-featured-item-instructor">
                        {workshop.date &&
                        workshop.date.start &&
                        workshop.date.end
                          ? `${getFormattedClassStartDate(
                              workshop.date.start
                            )} @${getFormattedClassStartTime(
                              workshop.date.start
                            )}`
                          : "Private Coaching"}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
        <section id="clubhouse" className="light">
          <div className="container text-center clubhouse-text">
            <h1>THE CLUBHOUSE</h1>
            <p className="fw-bold">
              Friendships and connections that take you places.
            </p>
            <p className="clubhouse-central-text">
              CLUBHOUSE, by The Workshop Club matches you with a learning
              partner based on your personal preferences. Currently operating in
              beta, enter your preferences such as age, gender, interest and
              experience level and our platform will match you with a suitable
              partner to put your new knowledge and skills into practice.
            </p>
            <p className="clubhouse-central-text">
              If you’ve experienced the pain of trying to convince a friend to
              take up a new hobby with you, or always wanted a partner to keep
              you motivated on your learning journey, we’ve got you.
            </p>
            <ExternalLink
              className="btn btn-color-invert"
              href={HREF_CLUBHOUSE}
            >
              Find a Match
            </ExternalLink>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

const mapStoreToProps = ({ cms, data }) => ({ cms, data });

export default compose(connect(mapStoreToProps))(HomePage);
