import "./firebase";
import "./fonts";
import "./styles";
import { initReduxStore } from "./firebase";
import { initFullStory } from "./fullstory";

export const loadPreRenderPlugins = () => {
  initReduxStore();
  initFullStory();
};

export const loadPostRenderPlugins = () => {};
