import moment from "moment";

export const getCurrentYear = () => moment().format("YYYY");
export const getFormattedClassStartDate = (date) =>
    moment.unix(date).format("DD MMM YY");
export const getFormattedClassStartTime = (date) =>
    moment.unix(date).format("h:mm a");
export const getHumanizedDuration = (start, end) =>
    getHumanizedDurationFromDuration(moment.duration(end - start, "seconds"));
export const getHumanizedDurationFromMinutes = (minutes) =>
    getHumanizedDurationFromDuration(moment.duration(minutes, "minutes"));
export const getHumanizedDurationFromDuration = (duration) => {
    let retVal = "";
    if (duration.hours() > 1) {
        retVal += `${duration.hours()} hours`;
    } else if (duration.hours() > 0) {
        retVal += `${duration.hours()} hour`;
    }
    if (duration.minutes() > 0) {
        retVal += `${duration.minutes()} mins`;
    }
    return retVal;
};
