import React from "react";
import "./AboutPage.css";
import Helmet from "react-helmet";
import { APP_TITLE } from "../../constants/helmet";
import jumboHangout from "../../assets/img/jumbotron/jumboAbout.jpg";

class AboutPage extends React.Component {
    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>Clubhouse | {APP_TITLE}</title>
                    <meta
                        property="og:title"
                        content={`Clubhouse | ${APP_TITLE}`}
                    />
                    <meta
                        property="og:image"
                        content={`${window.location.origin}/${jumboHangout}`}
                    />
                </Helmet>
                <div
                    className="jumbotron hero hero-about"
                    style={{
                        backgroundImage: `linear-gradient(0deg, rgba(0,0,0,1), rgba(0,0,0,0.2), rgba(0,0,0,0.1)), linear-gradient(90deg, rgba(0,0,0,1), rgba(0,0,0,0.2), rgba(0,0,0,0.1)), url(${jumboHangout})`,
                    }}
                >
                    <div className="container h-100">
                        <div className="row h-100">
                            <div className="col-lg-6 d-flex justify-content-center align-items-center flex-column text-center">
                                <div className="jumbotron-title">ABOUT US</div>
                                <div className="w-100">
                                    <div className="jumbotron-divider"> </div>
                                </div>
                                <div className="jumbotron-subtitle"> </div>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="dark">
                    <div className="container text-center about-text">
                        <h1>THE WORKSHOP CLUB</h1>
                        <p className="about-central-text">
                            The Workshop Club curates learning experiences for
                            people to expand their horizons and find new
                            connections to greatness. To go somewhere you’ve
                            never been, get something you’ve never had, be
                            someone you’ve always believed you could be – you
                            have to do something you’ve never done.
                        </p>
                        <p className="about-central-text">
                            As Singapore’s first leisure-learning marketplace,
                            we’re a one-stop platform to uncover new
                            possibilities, knowledge and connections to become
                            better, more learned and inspired versions of
                            ourselves. Currently launching in beta, we’ve teamed
                            up with expert coaches on a mission to help more
                            people learn what they want, to do what they love
                            with a rotating curation of activities, workshops
                            and masterclasses.
                        </p>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

export default AboutPage;
