import React from "react";
import ExternalLink from "../links/ExternalLink";
import { HREF_BOOK_NOW } from "../../constants/business";

class BookNowButton extends React.Component {
    render() {
        const { soldOut } = this.props;
        return soldOut ? (
            <button
                className="btn btn-lg disabled btn-color-normal"
                disabled
                {...this.props}
            >
                SOLD OUT
            </button>
        ) : (
            <ExternalLink
                role="button"
                className="btn btn-lg btn-color-normal"
                href={HREF_BOOK_NOW}
                {...this.props}
            >
                <i className="fab fa-whatsapp" /> {"Book Now"}
            </ExternalLink>
        );
    }
}

export default BookNowButton;
