import diyCopperPipeLamp1 from "../../assets/img/v1/workshops/DIY Copper Pipe Lamp/65AipgykxN5r.jpg";
import diyCopperPipeLamp2 from "../../assets/img/v1/workshops/DIY Copper Pipe Lamp/LCGKFaT8fyeS.jpg";
import diyCopperPipeLamp3 from "../../assets/img/v1/workshops/DIY Copper Pipe Lamp/IWiwx0cye1Q9.jpg";
import diyCopperPipeLamp4 from "../../assets/img/v1/workshops/DIY Copper Pipe Lamp/jRFoNlndyt8p.jpg";
import polymerClay1 from "../../assets/img/v1/workshops/Polymer Clay Jewelry Making/Im0lVZD357sy.jpg";
import polymerClay2 from "../../assets/img/v1/workshops/Polymer Clay Jewelry Making/0ZQ3W4UkpfpZ.jpg";
import polymerClay3 from "../../assets/img/v1/workshops/Polymer Clay Jewelry Making/oZnzmwqxrRQV.jpg";
import ukulele1 from "../../assets/img/v1/workshops/Ukulele Art Jamming/HUMvUOQiQMPz.jpg";
import ukulele2 from "../../assets/img/v1/workshops/Ukulele Art Jamming/veagnPxYMtfN.jpg";
import ukulele3 from "../../assets/img/v1/workshops/Ukulele Art Jamming/eWxJnaexFiTe.jpg";
import sneakerArt1 from "../../assets/img/v1/workshops/Sneaker Art and Customs/68xldhBWUU5A.jpg";
import sneakerArt2 from "../../assets/img/v1/workshops/Sneaker Art and Customs/Ssd460qeVbwC.jpg";
import sneakerArt3 from "../../assets/img/v1/workshops/Sneaker Art and Customs/hDAh4Qumghag.jpg";
import sneakerArt4 from "../../assets/img/v1/workshops/Sneaker Art and Customs/xyHbbtXI33er.jpg";
import resinJewelery1 from "../../assets/img/v1/workshops/Resin Jewelry Making/IrN1YETtrcJg.jpg";
import resinJewelery2 from "../../assets/img/v1/workshops/Resin Jewelry Making/uC5oJKVZ7U8G.jpg";
import resinJewelery3 from "../../assets/img/v1/workshops/Resin Jewelry Making/iiexOdcieTky.jpg";
import writingIsEditing1 from "../../assets/img/v1/workshops/Writing is Editing/4vmzz4fhyyRO.jpg";
import articulation1 from "../../assets/img/v1/workshops/Accent Reduction and Articulation/8LhLCQnDahKY.jpg";
import indianCuisine1 from "../../assets/img/v1/workshops/Discover Indian Cuisine and Culture/J9DJHhCiyGk1.jpg";
import indianCuisine2 from "../../assets/img/v1/workshops/Discover Indian Cuisine and Culture/Dq0b3zozk1vh.jpg";
import indianCuisine3 from "../../assets/img/v1/workshops/Discover Indian Cuisine and Culture/SAwj2Dyk09FK.jpg";

export const DATA_WORKSHOPS = [
  {
    id: "1",
    isFeatured: false,
    title: "DIY Copper Pipe Lamp",
    description:
      "Industrial product designer and craft workshop studio founder Wenxiang teaches you how to make your own stunning minimalist copper pipe lamp.",
    details: {
      v1: {
        content:
          "This virtual workshop will provide you with an introduction to copperworks where you’ll be provided with a DIY-kit and live instruction on how to assemble your own stunning, minimalist copper pipe lamp. \n" +
          "In the comfort of your own home, you’ll be able to learn the basics of hardware materials and electrical wiring and build a fully functional, timeliness and chic industrial lamp that will make the perfect companion for any part of your home that needs a little light and inspiration. \n" +
          "The lesson plan focuses on building a unique lamp designed by trainer Wenxiang and includes learning how to assemble the structure and body of your lamp, followed by connecting pipe fittings to electrical wiring. \n" +
          "When that’s all done, you’ll fit in the incandescent bulb and “tune-the-light” to complete your copper pipe lamp and ultimate conversation piece – the one you make yourself. \n" +
          "When you make a booking, your DIY Copper Pipe Lamp kit with virtual Zoom dial-in link will be delivered via courier to your mailing address at least 3 working days before the commencement date of the workshop.  \n",
        whatYouGet: [
          "DIY Copper Pipe Lamp Kit (all material and tools provided)",
        ],
        whatToBring: ["Lots of enthusiasm"],
        termsAndConditions: [
          "Customer to provide mailing address and contact details (name, mobile) upon booking confirmation",
          "No refunds or cancellations after 30 July 2021",
          "Participants who missed out on the virtual workshop can request a QR code / link for video instructions",
        ],
      },
    },
    instructorId: "i1",
    categoryId: "c1",
    subCategories: [],
    imgHomePageListing: diyCopperPipeLamp1,
    imgFeaturedJumboDesktop: diyCopperPipeLamp1,
    imgWorkshopJumboCarousel: [
      diyCopperPipeLamp1,
      diyCopperPipeLamp2,
      diyCopperPipeLamp3,
      diyCopperPipeLamp4,
    ],
    imgWorkshopGallery: [
      {
        src: diyCopperPipeLamp1,
        width: 3,
        height: 2,
      },
      {
        src: diyCopperPipeLamp2,
        width: 3,
        height: 2,
      },
      {
        src: diyCopperPipeLamp3,
        width: 3,
        height: 2,
      },
      {
        src: diyCopperPipeLamp4,
        width: 3,
        height: 2,
      },
    ],
    date: {
      start: 1628312400,
      end: 1628316000,
    },
    groupSize: {
      min: 10,
      max: 30,
    },
    ages: {
      min: 9,
    },
    price: {
      amount: 61.0,
      currency: "SGD",
    },
    soldOut: true,
    teachingMethods: {
      online: true,
      inPerson: false,
    },
  },
  {
    id: "2",
    isFeatured: false,
    title: "Writing is Editing",
    description:
      "Veteran editor and prize-winning playwright David teaches you how to write with impact using editing techniques for clear, concise, and creative communication.",
    details: {
      v1: {
        content:
          "In Writing is Editing, you’ll learn how to write and edit better. From essays to emails, press releases to annual reports, blog posts to cover letters, your job as a writer is to make the reader understand your meaning precisely and purposefully—do you always say what you mean? \n" +
          "We all need to appreciate the importance of language and the need to express ourselves clearly and coherently. \n" +
          "In one hour, David will jumpstart your writing project and help you learn to eliminate common grammatical and syntactical errors:\n" +
          "• You’ll learn to remove redundancies from phrases. (There’s a word for it!)\n" +
          "• You’ll learn tips for writing good sentences. (Avoid cliches like the plague.)\n" +
          "• You’ll learn why it’s “big, red house” and not “red, big house”.\n" +
          "• You’ll learn the difference between the semicolon and the colon: it’s easy once you try it.\n" +
          "The craft of writing is a lifelong calling, and the sooner you learn to appreciate the language, the better your writing will become.\n",
        whatYouGet: [
          "One-hour personalised private lesson",
          "Expert instruction and real-time editing tips",
          "Personal writing lesson",
          "Reading resources",
        ],
        whatToBring: ["N/A (Online Zoom lesson, link provided)"],
        termsAndConditions: [
          "Bookings made are to be utilised by 31 August 2021 or will be considered void",
          "Cancellations within 48 hours of appointment will incur cancellation costs",
        ],
      },
    },
    instructorId: "i2",
    categoryId: "c5",
    subCategories: [],
    imgHomePageListing: writingIsEditing1,
    imgFeaturedJumboDesktop: writingIsEditing1,
    imgWorkshopJumboCarousel: [writingIsEditing1],
    date: {
      durationMinutes: 60,
      start: undefined,
      end: undefined,
    },
    groupSize: {
      min: 1,
      max: 2,
    },
    ages: {
      min: 18,
    },
    price: {
      priceType: "Per Hour",
      amount: 150.0,
      currency: "SGD",
    },
    soldOut: true,
    teachingMethods: {
      online: true,
      inPerson: false,
    },
  },
  {
    id: "3",
    isFeatured: false,
    title: "Ukulele Art Jamming",
    description:
      "DIY Ukulele Instructor Lidya teaches you how to assemble and customise your own ukulele in an unusual art jamming experience with a personal touch.",
    details: {
      v1: {
        content:
          "The ukulele is a small string instrument and a great project for beginners because of its portability and ease of play. With a similar look to guitars which generally have six strings, ukuleles tend to have four and are tuned differently. \n" +
          "Let the ukulele be your canvas with this beginner friendly, in-person art jamming session where you’ll learn how to assemble, design and string your ukulele for play! \n" +
          "In this four-hour workshop, you’ll be guided on how to paint your designs onto the ukulele, followed by minor assembly work to complete the instrument.  \n" +
          "You’ll receive live instructions and tips on how to customise the design of your ukulele using mainly acrylic paint, learn how to attach the tuning knobs onto the ukulele, and string the instrument for a fully functioning ukulele – and lots of great memories when you are finished! \n",
        whatYouGet: [
          "Ukulele parts",
          "Acrylic paint and brushes",
          "Screwdriver",
          "Ukulele carry-bag",
        ],
        whatToBring: [
          "Apron or shirt that you are willing to get stains on",
          "Print-out or digital picture of your intended ukulele design",
          "Any additional art materials needed for your design",
        ],
        termsAndConditions: [
          "No refunds or cancellations after 20 August 2021",
        ],
        locations: ["Upper Aljunied Lane"],
      },
      v2: {
        i18n: [
          {
            locale: "",
          },
        ],
      },
    },
    instructorId: "i3",
    categoryId: "c3",
    subCategories: [],
    imgHomePageListing: ukulele1,
    imgFeaturedJumboDesktop: ukulele3,
    imgWorkshopJumboCarousel: [ukulele1, ukulele2, ukulele3],
    imgWorkshopGallery: [
      {
        src: ukulele1,
        width: 3,
        height: 2,
      },
      {
        src: ukulele2,
        width: 3,
        height: 2,
      },
      {
        src: ukulele3,
        width: 3,
        height: 2,
      },
    ],
    date: {
      start: 1630126800,
      end: 1630141200,
    },
    groupSize: {
      min: 4,
    },
    ages: {
      min: 10,
    },
    price: {
      amount: 90.0,
      currency: "SGD",
    },
    soldOut: true,
    teachingMethods: {
      online: false,
      inPerson: true,
    },
  },
  {
    id: "4",
    isFeatured: false,
    title: "Sneaker Art and Customs",
    description:
      "Sneaker artist and passion-preneur Ashley teaches you the art of creating killer custom-designed kicks with fundamentals in surface prep, masking, stencil, painting and finishing.",
    details: {
      v1: {
        content:
          "This beginner friendly, in-person workshop will bring you through the process of creating your own custom sneaker from scratch, focusing on cartoon-style designs. \n" +
          "Expect lots of hands-on practice and little theory, making the workshop format ideal for those looking to have a not-your-usual art jamming session with free flow of conversation and personalized instruction. \n" +
          "The session will start off with an introduction of surface prep where you’ll learn why this is an important fundamental to ensure that paint bonds properly to prevent cracks and peeling. \n" +
          "Next, try your hand at a best-kept technique used by professional painters –  masking –  to protect parts of the sneakers you don’t want the paint to touch and get creative with techniques for linework, painting and stencil. The lesson will cover basic brush strokes, how to paint smoothly and how to paint intricate designs. \n" +
          "The class will end off with clear finishing techniques and you’ll walk away with a killer hand-painted custom shoe, made by yours truly. \n",
        whatYouGet: [
          "A pair of white, low-rise sneakers for customization (for take-home)",
          "Clear transparent plastic shoe display box (for take-home)",
          "Stencils for intricate designs",
          "Paintbrushes",
          "Angelus Paint",
          "Hair-dryer for quicker drying",
          "Water, towels and paint palettes",
        ],
        whatToBring: ["Apron or shirt that you are willing to get stain on"],
        termsAndConditions: [
          "Customer to provide shoe size in EU sizing upon booking confirmation",
          "No refunds or cancellations after 7 August 2021",
        ],
        locations: ["Upper Aljunied Lane"],
      },
    },
    instructorId: "i6",
    categoryId: "c4",
    subCategories: [],
    imgHomePageListing: sneakerArt1,
    imgFeaturedJumboDesktop: sneakerArt2,
    imgWorkshopJumboCarousel: [
      sneakerArt1,
      sneakerArt2,
      sneakerArt3,
      sneakerArt4,
    ],
    imgWorkshopGallery: [
      {
        src: sneakerArt1,
        width: 3,
        height: 2,
      },
      {
        src: sneakerArt2,
        width: 3,
        height: 2,
      },
      {
        src: sneakerArt3,
        width: 3,
        height: 2,
      },
      {
        src: sneakerArt4,
        width: 3,
        height: 2,
      },
    ],
    date: {
      start: 1629523800,
      end: 1629538200,
    },
    groupSize: {
      min: 2,
      max: 4,
    },
    ages: {
      min: 5,
    },
    price: {
      amount: 120.0,
      currency: "SGD",
    },
    soldOut: true,
    teachingMethods: {
      online: false,
      inPerson: true,
    },
  },
  {
    id: "5",
    isFeatured: true,
    title: "Resin Jewelry Making",
    description:
      "Founder and Creative Director of DIY jewelry making studio Samantha teaches you basic resin handling and earring decoration techniques",
    details: {
      v1: {
        content:
          "This in-person workshop will empower you with basic technical skills to be able to create an exquisite pair of resin earrings. \n" +
          "Resin is a composite blend of plastic, known for its strength, affordability and flexibility to be made into any shape, size or color. \n" +
          "The session will start with an introduction of basic resin handling techniques and an instructional of the various components of the craft kit provided. \n" +
          "You’ll start your crafting journey spending some time letting your creative juices flow and planning your earring design (dangle, stud or clip-ons available) before picking a mould for your first resin pour. You’ll also learn a nifty trick to ensure that air bubbles are removed from your cast. \n" +
          "Following which, you’ll be able to take your pick from an assortment of dried flowers and gold, silver or rose gold foils available for the design and decorative process, with Samantha on standby for guidance and troubleshooting to perfection. \n" +
          "Once you’re happy with your product, it’ll be sent into a UV lamp to cure and you’re ready to remove it from the mould, sand it and connect your masterpiece to an earring hook for a glamorous night out.\n",
        whatYouGet: [
          "2 hour workshop experience",
          "DIY resin earrings kit (dangle, stud, clip-on options)",
          "Dried flowers, gold/silver/rose gold foils",
          "1 pair of resin earrings to take-home",
        ],
        whatToBring: [
          "All materials are provided and you are not required to bring anything to the class",
        ],
        termsAndConditions: [
          "No refunds or cancellations",
          "Enjoy a limited time discount when you book 2 pax for $158",
        ],
        locations: ["Orchard Gateway"],
      },
    },
    instructorId: "i4",
    categoryId: "c2",
    subCategories: [],
    imgHomePageListing: resinJewelery1,
    imgFeaturedJumboDesktop: resinJewelery2,
    imgWorkshopJumboCarousel: [resinJewelery1, resinJewelery2, resinJewelery3],
    imgWorkshopGallery: [
      {
        src: resinJewelery1,
        width: 3,
        height: 2,
      },
      {
        src: resinJewelery2,
        width: 3,
        height: 2,
      },
      {
        src: resinJewelery3,
        width: 3,
        height: 2,
      },
    ],
    date: {
      start: 1629516600,
      end: 1629523800,
    },
    groupSize: {
      min: 5,
      max: 10,
    },
    ages: {
      min: 5,
    },
    price: {
      amount: 88.0,
      currency: "SGD",
    },
    soldOut: true,
    teachingMethods: {
      online: false,
      inPerson: true,
    },
  },
  {
    id: "6",
    isFeatured: false,
    title: "Polymer Clay Jewelry Making",
    description:
      "Founder and Creative Director of DIY jewelry making studio Samantha teaches you how to make stunning polymer clay earring with marble swirls.",
    details: {
      v1: {
        content:
          "This in-person workshop will provide you with a basic introduction to create your own polymer clay earrings, focusing on marbling and swirl design techniques. \n" +
          "Polymer is a synthetic, modelling clay product that can be moulded until it is cured in the oven.  \n" +
          "The session will start off with students learning how to prepare and condition the polymer clay to the perfect consistency and thickness. \n" +
          "Once the clay is ready to be worked with, you’ll be able to pick and choose your mould to your desired shape or if you wish for more customized designs, use a cutter to make your own.  \n" +
          "You’ll then learn the technique of combining different colours together to create a marbling or swirl pattern for your earring. You can choose to make it dangle or make a stud one. For those who don’t have punched ears, clip-ons are also available. \n" +
          "To finish your piece, Samantha will guide you on how to delicately puncture a tiny hole slightly off the edge before sending it off ‘to bake’ in the oven for approximately 20 minutes. \n" +
          "With your bakes out of the oven, it is time to assemble and connect the clay pieces through earring hoops using pliers and get ready to show off your delightful, one-of-a-kind creation to friends and family.\n",
        whatYouGet: [
          "2 hour workshop experience",
          "DIY polymer clay earrings kit (dangle, stud, clip-on options)",
          "Polymer clay and metal foils for decoration",
          "1 pair of marbling swirls earrings to take home",
        ],
        whatToBring: [
          "All materials are provided and you are not required to bring anything to the class ",
        ],
        termsAndConditions: [
          "No refunds or cancellations",
          "Enjoy a limited time discount when you book 2 pax for $158",
        ],
        locations: ["Orchard Gateway"],
      },
    },
    instructorId: "i4",
    categoryId: "c2",
    subCategories: [],
    imgHomePageListing: polymerClay1,
    imgFeaturedJumboDesktop: polymerClay1,
    imgWorkshopJumboCarousel: [polymerClay1, polymerClay2, polymerClay3],
    imgWorkshopGallery: [
      {
        src: polymerClay1,
        width: 3,
        height: 2,
      },
      {
        src: polymerClay2,
        width: 3,
        height: 2,
      },
      {
        src: polymerClay3,
        width: 3,
        height: 2,
      },
    ],
    date: {
      start: 1630135800,
      end: 1630143000,
    },
    groupSize: {
      min: 4,
      max: 8,
    },
    ages: {
      min: 5,
    },
    price: {
      amount: 88.0,
      currency: "SGD",
    },
    soldOut: true,
    teachingMethods: {
      online: false,
      inPerson: true,
    },
  },
  {
    id: "7",
    isFeatured: false,
    title: "Accent Reduction and Articulation",
    description:
      "Speech pathologist Kym teaches you how to modify pronunciation and speak with a more international and neutral accent to communicate with clarity.",
    details: {
      v1: {
        content:
          "This taster class will be delivered as a private one-hour session. Clients choose the sound they would like to work on e.g. th a sound that is often an issue for Asian students.  No assessment is undertaken, but clients can later choose to do this.  \n" +
          "Kym offers accent assessment, priced at S$300 where you will find out which sounds you are saying accurately and inaccurately compared with General Australian English. Comparisons can be given in relation to Standard Neutral English and General American Accent if required.  A report and accent profile are emailed following the assessment.  You may then choose to undertake training.\n" +
          "Accent reduction isn’t about adopting a new accent or speaking like someone you are not.  The aim is to progress pronunciation to achieve far greater clarity and intelligibility so you can be easily and clearly understood in local to global business and social environments.\n" +
          "Upon booking, your coach will connect with you via email within three business days to discuss your requirements and schedule your appointment. \n" +
          "To continue beyond your first class, you can make bookings directly with Kym. All classes are conducted virtually via Zoom as Kym is based in Perth, Western Australia.\n",
        whatYouGet: [
          "1-hour personalized private lesson (English)",
          "Expert instruction in accent reduction and individual feedback",
          "Worksheets for individual sounds, word, phase and sentence level practice ",
          "Audio training MP3/Wav Files are given after each session",
        ],
        whatToBring: ["N/A (Online Zoom lesson, link provided)"],
        termsAndConditions: [
          "Bookings made are to be utilized by 31 August 2021 or will be considered void",
          "Cancellations within 48 hours of appointment will incur cancellation costs",
        ],
      },
    },
    instructorId: "i5",
    categoryId: "c6",
    subCategories: [],
    imgHomePageListing: articulation1,
    imgFeaturedJumboDesktop: articulation1,
    imgWorkshopJumboCarousel: [articulation1],
    date: {
      durationMinutes: 60,
      start: undefined,
      end: undefined,
    },
    groupSize: {
      min: 1,
      // max: 30,
    },
    ages: {
      min: 18,
    },
    price: {
      priceType: "Per Hour",
      amount: 150.0,
      currency: "SGD",
    },
    soldOut: true,
    teachingMethods: {
      online: true,
      inPerson: false,
    },
  },
  {
    id: "8",
    isFeatured: false,
    title: "Discover Indian Cuisine and Culture",
    description:
      "Avid home cook Louise shares her culinary expertise and some of her most prized recipes in an intimate cooking class hosted in her very own home.",
    details: {
      v1: {
        content:
          "Indian cuisine is widely appreciated for its use of herbs and spices and is known to be prepared and served in a large assortment of dishes. The food can range from very simple vegetarian fare to dishes that are layered with texture and flavour. A lot of care is taken in the planning and preparation of meals, no matter how humble or complex.\n" +
          "This in-person cooking lesson will be hosted in the personal home of Louise and will start off with the most important step of preparing an Indian meal – learning how to plan for your home cooked meal. \n" +
          "The next part of the session will introduce Indian spices and the art of making incredible spice blends. Over table conversation, you’ll learn how to make the spices work together to create exciting, layered flavours and sensations. \n" +
          "Louise will share some of her best-kept secrets with guided, step-by-step live demonstrations to turn your meal plan into a gastronomic feast for friends or family. \n" +
          "As part of this cooking lesson, you’ll learn how to create authentic Indian dishes such as brown onion chicken curry, paneer mutter kurma, coconut mustard and cumin seeds broccoli, boondi raita, butter naan and gulab jamun cake – a six-course delight and feast for the senses.\n",
        whatYouGet: [
          "4-hour cooking lesson",
          "Planning a home cooked Indian meal",
          "An introduction into Indian spices",
          "You will learn how to cook authentic Indian dishes including Brown Onion Chicken Curry, Paneer Mutter Kurma, Coconut Mustard and Cumin seeds broccoli, Boondi Raita, Butter Naan and Gulab Jamun Cake",
        ],
        whatToBring: [
          "All class materials are provided",
          "Bring take-home containers if you intend to bring some of your meals home",
        ],
        termsAndConditions: [
          "No refunds or cancellations after 18 August 2021",
        ],
        locations: ["Bukit Timah"],
      },
    },
    instructorId: "i7",
    categoryId: "c7",
    subCategories: [],
    imgHomePageListing: indianCuisine1,
    imgFeaturedJumboDesktop: indianCuisine1,
    imgWorkshopJumboCarousel: [indianCuisine1, indianCuisine2, indianCuisine3],
    imgWorkshopGallery: [
      {
        src: indianCuisine1,
        width: 3,
        height: 2,
      },
      {
        src: indianCuisine2,
        width: 3,
        height: 2,
      },
      {
        src: indianCuisine3,
        width: 3,
        height: 2,
      },
    ],
    date: {
      start: 1629943200,
      end: 1629957600,
    },
    groupSize: {
      min: 2,
    },
    ages: {
      min: 10,
    },
    price: {
      amount: 110.0,
      currency: "SGD",
    },
    soldOut: false,
    teachingMethods: {
      online: false,
      inPerson: true,
    },
  },
];

export const homePageComparator = (a, b) => {
  if (!!a.date.start && !b.date.start) {
    return -1;
  } else if (!a.date.start && !!b.date.start) {
    return 1;
  } else if (!!a.date.start && !!b.date.start) {
    return a.date.start - b.date.start;
  } else {
    return a.durationMinutes - b.durationMinutes;
  }
};
