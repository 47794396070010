import {
  collection,
  doc,
  getDocs,
  getFirestore,
  onSnapshot,
  query,
  orderBy,
} from "firebase/firestore";
import { getPerformance, trace } from "firebase/performance";

export const watchCmsHome = (func) => {
  const docRef = doc(getFirestore(), "v1", "cms", "home");
  const unsub = onSnapshot(docRef, func);
  return unsub;
};

export const getCmsFaq = async () => {
  const t = trace(getPerformance(), "getCmsFaq");
  const q = query(
    collection(getFirestore(), "cms", "faq", "faqs"),
    orderBy("sortOrder", "asc")
  );
  t.start();
  const querySnapshot = await getDocs(q);
  t.stop();
  const faqs = new Array(querySnapshot.size);
  t.incrementMetric("getCmsFaqLen", faqs.length);
  let i = 0;
  querySnapshot.forEach((docSnap) => {
    // doc.data() is never undefined for query doc snapshots
    faqs[i] = {
      id: docSnap.id,
      ...docSnap.data(),
    };
    i++;
  });
  return faqs;
};
