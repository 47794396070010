import wenxiangPoh from "../../assets/img/v1/instructors/T3T5uDlj9q3T.jpg";
import lidya from "../../assets/img/v1/instructors/OywCgdVk7S1H.jpg";
import davidLim from "../../assets/img/v1/instructors/jjEl5iVmHwUn.jpg";
import kymGodfrey from "../../assets/img/v1/instructors/Y2z4EJtn0ecd.jpg";
import samanthaLim from "../../assets/img/v1/instructors/hVLx7RmYpxVu.jpg";
import ashleyLer from "../../assets/img/v1/instructors/rHBKCTsn7Cnc.jpg";
import louiseYuan from "../../assets/img/v1/instructors/hxnlsuUFQPkG.jpg";

export const DATA_INSTRUCTORS = {
  i1: {
    name: "Wenxiang Poh",
    avatar: wenxiangPoh,
    biography:
      "Wenxiang is a trained industrial product designer and trainer of craft workshop studio, Make Your Own which he founded in 2013 after having various experience in the design industry. Being a passionate DIY enthusiast with a purpose to bring appreciation for craft projects to a wider audience, Wenxiang is a strong advocate of Singapore’s maker community and hopes to provide a fun, engaging and accessible platform for new makers looking to be part of the community or step up their game growing their craftsmanship.",
  },
  i2: {
    name: "David Lim Fuhrmann",
    avatar: davidLim,
    biography:
      "David FUHRMANN LIM is a Pencil-winning author, prize-winning playwright, and acclaimed editor. He is one of the authors of the Louis Vuitton City Guide – Singapore Edition and currently edits Parched, an online magazine about cocktail culture. He was a Contributing Editor at SPH Magazines and the Editor-in-Chief of the award-winning men's lifestyle magazine Singapore FHM. While there, he collaborated with Ogilvy & Mather Asia Pacific on an FHM marketing campaign and won the Gold Award at Cannes Lions Festival. As a veteran content creator and communicator, David understands the power of the written word and the need for everyone to deliver clear, concise, and creative writing.",
  },
  i3: {
    name: "Lidya Harjadi",
    avatar: lidya,
    biography:
      "LIDYA is a full-time ukulele teacher and MOE-AMIS Certified instructor. Starting her career in 2010, she has cultivated a strong passion for music and the ukulele, with more than a decade of experience teaching in corporations, government schools, as well as voluntary welfare organisations. Through her DIY ukulele workshops, Lidya hopes to introduce the ukulele to more people in a creative and meaningful way, to bring the joy and connection of music closer to the world.",
  },
  i4: {
    name: "Samantha Lim",
    avatar: samanthaLim,
    biography:
      "SAMANTHA is the Creative Director and founder of a Singapore-based DIY craft jewelry workshop studio. Previously a flight attendant with Singapore Airlines, Samantha discovered the art of jewelry making during one of her layover trips and not long after, found herself a new serial hobby.  After spending three years honing her craft and exploring different mediums such as polymer clay and resin, she began hosting workshops to share her passion with other enthusiasts in the community looking for a cosy and friendly hangout or a dose of me-time therapy.",
  },
  i5: {
    name: "Kym Godfrey",
    avatar: kymGodfrey,
    biography:
      "Kym has more than 15 years experience working as a speech pathologist and communications consultant in the private sector, health, education and not-for-profit sectors. Currently based in Perth, Western Australia, she is the director of a communication skills training company which provides state of the art programs in accent reduction, articulation, public speaking and voice training, supported by speech pathology expertise in linguistics and phonology.",
  },
  i6: {
    name: "Ashley Ler",
    avatar: ashleyLer,
    biography:
      "ASHLEY is a self-taught sneaker artist and founder of a custom-painted shoe business. Known for her raw and edgy paintwork on Air Force Ones, Ashley’s claim to fame kicked-off on Tik Tok where one of her passion projects caught the eyes of sneakerheads looking for commissioned, one-of-a-kind pieces to add a personal touch to their wardrobes. As a self-taught sneaker customizer, Ashley understands the struggles of beginners looking to get into the craft. Her teaching style and workshops are ideal for those looking for something fun, casual and personalized – almost like having a friend guide you through the process.",
  },
  i7: {
    name: "Louise Yuan",
    avatar: louiseYuan,
    biography:
      "LOUISE is an avid home cook and founder of a health snacks business. After spending more than two decades in the corporate world working in nutrition related specializations, she realized that she enjoyed cooking up memorable culinary experiences more than marketing team strategies and took her passion full-time. Louise runs a regular roster of cooking classes, hosted in her personal home.",
  },
};

export const getFormattedInstructorName = (instructor) => {
  if (!instructor) {
    return "";
  }
  const nameWords = instructor.name.split(" ");
  if (nameWords.length === 1) {
    return instructor.name;
  }
  const firstName = nameWords[0];
  const lastNameChar =
    nameWords[nameWords.length - 1].charAt(0).toUpperCase() + ".";
  return firstName + " " + lastNameChar;
};
