import React from "react";
import "./NotFoundPage.css";
import Helmet from "react-helmet";
import { APP_TITLE } from "../../constants/helmet";
import jumbo404 from "../../assets/img/jumbotron/jumbo404.png";
import { Link } from "react-router-dom";
import { ROUTE_HOME } from "../../constants/routes";

class NotFoundPage extends React.Component {
    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>Not found | {APP_TITLE}</title>
                </Helmet>
                <div
                    className="jumbotron hero hero-404"
                    style={{
                        backgroundImage: `linear-gradient(0deg, rgba(0,0,0,1), rgba(0,0,0,0), rgba(0,0,0,0)), url(${jumbo404})`,
                    }}
                >
                    <div className="h-100 d-flex flex-column justify-content-center align-items-center">
                        <h1>404</h1>
                        <p>Oh no</p>
                        <Link
                            role="button"
                            className="btn btn-lg btn-color-normal"
                            to={ROUTE_HOME}
                        >
                            GO HOME
                        </Link>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default NotFoundPage;
