export const ROUTE_HOME = "/";
export const ROUTE_WORKSHOPS = "/workshops";
export const ROUTE_WORKSHOP = "/workshops/:workshopId";
export const ROUTE_ABOUT = "/about";
export const ROUTE_FAQ = "/faq";
export const ROUTE_CLUBHOUSE = "/#clubhouse";
export const ROUTE_NOT_FOUND = "/404";
export const ROUTE_WILDCARD = "*";

export const NAV_LINKS = [
    {
        to: ROUTE_HOME,
        display: "HOME",
        isVisibleFooterNav: true,
        isVisibleDesktopNav: false,
        isVisibleNavMenu: true,
    },
    {
        to: ROUTE_CLUBHOUSE,
        display: "CLUBHOUSE",
        isVisibleFooterNav: true,
        isVisibleDesktopNav: true,
        isVisibleNavMenu: true,
    },
    {
        to: ROUTE_WORKSHOPS,
        display: "WORKSHOPS",
        isVisibleFooterNav: false,
        isVisibleDesktopNav: false,
        isVisibleNavMenu: false,
    },
    {
        to: ROUTE_ABOUT,
        display: "ABOUT",
        isVisibleFooterNav: true,
        isVisibleDesktopNav: true,
        isVisibleNavMenu: true,
    },
    {
        to: ROUTE_FAQ,
        display: "FAQS",
        isVisibleFooterNav: true,
        isVisibleDesktopNav: true,
        isVisibleNavMenu: true,
    },
];
