import { DATA_WORKSHOPS } from "../../constants/v1/workshops";

export const GetJumboWorkshop = async (retries = 3) => {
  // import { getUserLocales } from "get-user-locale";
  // console.info({ user_locales: getUserLocales() }); TODO: analytics for correct language
  const featured = DATA_WORKSHOPS.filter(({ isFeatured }) => isFeatured);
  if (featured.length < 1) {
    console.info("No featured workshop for the week");
    return DATA_WORKSHOPS[0];
  } else if (featured.length > 1) {
    console.warn(
      "Multiple featured workshops! Randomly selecting a featured workshop."
    );
    return featured[Math.floor(Math.random() * featured.length)];
  } else {
    return featured[0];
  }
};

export const GetClassById = async (classId) => {
  const classes = DATA_WORKSHOPS.filter((workshop) => workshop.id === classId);
  if (classes.length === 0) {
    throw new Error(`Class ID ${classId} not found`);
  }
  return classes[0];
};
