import React from "react";
import { withRouter } from "react-router-dom";

/**
 * This higher-order component resets the page to the top
 * when navigating to a different route.
 */
class Scroller extends React.Component {
    scrollToPoint = (prevProps) => {
        const { location } = this.props;
        if (location !== prevProps.location) {
            if (!location.hash) window.scrollTo(0, 0);
            else {
                const section = document.getElementById(
                    location.hash.substring(1)
                );
                if (!section) window.scrollTo(0, 0);
                else section.scrollIntoView();
            }
        }
    };

    componentDidUpdate(prevProps) {
        this.scrollToPoint(prevProps);
    }

    render() {
        return this.props.children;
    }
}

export default withRouter(Scroller);
