import {
    fetchAndActivate,
    getRemoteConfig,
    getValue,
} from "firebase/remote-config";

const KEY_CONFIG = "app";

export const getConfig = async () => {
    const remoteConfig = getRemoteConfig();
    await fetchAndActivate(remoteConfig);
    const configValue = await getValue(remoteConfig, KEY_CONFIG);

    const config = JSON.parse(configValue.asString());
    console.debug("modules_configda_getconfig_success", config);
    return config;
};

export const watchConfig = (callback, milliseconds) => {
    const remoteConfig = getRemoteConfig();
    setTimeout(function activateConfig() {
        fetchAndActivate(remoteConfig)
            .then(() => getValue(remoteConfig, KEY_CONFIG))
            .then((configValue) => JSON.parse(configValue.asString()))
            .then((config) => {
                if (!!callback) callback(config);
                console.debug("modules_configda_watchconfig_success", config);
            })
            .catch((err) =>
                console.error("modules_configda_watchconfig_activate", err)
            );
        setTimeout(activateConfig, milliseconds);
    }, milliseconds);
};
