/**
 * Import styles:
 * https://firebase.google.com/docs/web/setup?sdk_version=v9
 */

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { initializePerformance } from "firebase/performance";
import { enableIndexedDbPersistence, getFirestore } from "firebase/firestore";
import { getCmsFaq } from "../modules/cmsda/cmsda";
import { getRemoteConfig } from "firebase/remote-config";
import store from "../redux/store";
import { ACTION_SET_CMS, ACTION_SET_CONFIG } from "../redux/constants";
import { getConfig, watchConfig } from "../modules/configda/configda";

export const firebaseConfig = {
  apiKey: "AIzaSyAenZtAbnTzfIOjkaRHRNsdP0tV8IDDbXo",
  authDomain: "workshop-club.firebaseapp.com",
  projectId: "workshop-club",
  storageBucket: "workshop-club.appspot.com",
  messagingSenderId: "573928222541",
  appId: "1:573928222541:web:bc080f2bbd1100ae701104",
  measurementId: "G-TL5FYTLRTV",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

/**
 * https://firebase.google.com/docs/perf-mon/get-started-web
 * @type {FirebasePerformance}
 */
initializePerformance(app);

/**
 * https://firebase.google.com/docs/analytics/get-started?platform=web#web-v9
 * @type {Analytics}
 */
getAnalytics(app);

/**
 * https://firebase.google.com/docs/firestore/quickstart
 * @type {FirebaseFirestore}
 */
const db = getFirestore(app);

/**
 * Access data offline
 * https://firebase.google.com/docs/firestore/manage-data/enable-offline
 */
enableIndexedDbPersistence(db).catch((err) => {
  if (err.code === "failed-precondition") {
    // Multiple tabs open, persistence can only be enabled
    // in one tab at a a time.
    console.warn("plugins_firebase_enable_indexed_db_persistence_multi_tabs");
  } else if (err.code === "unimplemented") {
    // The current browser does not support all of the
    // features required to enable persistence
    console.warn(
      "plugins_firebase_enable_indexed_db_persistence_unimplemented"
    );
  } else {
    console.error(
      "plugins_firebase_enable_indexed_db_persistence_unknown",
      err.code,
      err
    );
  }
});

/**
 * Set up remote config
 * https://firebase.google.com/docs/remote-config/get-started?platform=web#web-v9
 */
const remoteConfig = getRemoteConfig();
remoteConfig.settings.minimumFetchIntervalMillis =
  process.env.NODE_ENV === "production" ? 600000 : 10000;
remoteConfig.defaultConfig = {};

const dispatchConfigToStore = (config) =>
  store.dispatch({ type: ACTION_SET_CONFIG, config });

export const initReduxStore = () => {
  getConfig()
    .then(dispatchConfigToStore)
    .then(() =>
      watchConfig(
        dispatchConfigToStore,
        remoteConfig.settings.minimumFetchIntervalMillis
      )
    )
    .catch((err) =>
      console.error("plugins_firebase_init_redux_store_get_configs", err)
    );
  getCmsFaq()
    .then((faqs) => store.dispatch({ type: ACTION_SET_CMS, data: { faqs } }))
    .catch((err) =>
      console.error("plugins_firebase_init_redux_store_get_cms_faq", err)
    );
};
