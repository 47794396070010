/**
 * Documentation: https://currency.js.org/
 */
import currency from "currency.js";

const CURRENCY_FORMATS = {
    SGD: {
        formatter: (value) => currency(value),
    },
    USD: {
        formatter: (value) => currency(value),
    },
    JPY: {
        formatter: (value) => currency(value, { precision: 0, symbol: "¥" }),
    },
    EUR: {
        formatter: (value) =>
            currency(value, { symbol: "€", decimal: ",", separator: "." }),
    },
};

export const getCurrency = (amount, ccy) => {
    if (ccy in CURRENCY_FORMATS) {
        return CURRENCY_FORMATS[ccy].formatter(amount).format();
    }
    throw new Error(`Currency ${ccy} not registered.`);
};
