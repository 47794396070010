import React from "react";
import "./FaqPage.css";
import Helmet from "react-helmet";
import { APP_TITLE } from "../../constants/helmet";
import jumboFaq from "../../assets/img/jumbotron/jumboFaq.jpg";
import { compose } from "redux";
import { connect } from "react-redux";

class Faq extends React.Component {
    render() {
        const { faq, index, openId } = this.props;
        const isOpen = faq.id === openId;
        return (
            <div className="faq-item">
                <div className="question" onClick={this.props.setOpen(faq)}>
                    {index + 1}. {faq.q}
                </div>
                <div className={`answer${isOpen ? " expand" : ""}`}>
                    {faq.a.split("\n").map((p, pIndex) => (
                        <p key={pIndex}>{p}</p>
                    ))}
                </div>
            </div>
        );
    }
}

class FaqPage extends React.Component {
    state = { openId: "" };

    setOpen = (faq) => () =>
        this.setState(({ openId }) => ({
            openId: openId === faq.id ? "" : faq.id,
        }));

    getFaq = () => {
        const { cms } = this.props;
        if (!!cms && !!cms.faqs && !!cms.faqs.length) {
            return cms.faqs;
        }
        return [];
    };

    render() {
        const faqs = this.getFaq();
        return (
            <React.Fragment>
                <Helmet>
                    <title>FAQs | {APP_TITLE}</title>
                </Helmet>
                <div
                    className="jumbotron hero hero-faq"
                    style={{
                        backgroundImage: `linear-gradient(0deg, rgba(0,0,0,1), rgba(0,0,0,0.2), rgba(0,0,0,0.1)), linear-gradient(90deg, rgba(0,0,0,1), rgba(0,0,0,0.2), rgba(0,0,0,0.1)), url(${jumboFaq})`,
                    }}
                >
                    <div className="container h-100">
                        <div className="row h-100">
                            <div className="col-lg-6 d-flex justify-content-center align-items-center flex-column text-center">
                                <div className="jumbotron-title">FAQs</div>
                                <div className="w-100">
                                    <div className="jumbotron-divider"> </div>
                                </div>
                                <div className="jumbotron-subtitle"> </div>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="dark">
                    <div className="container">
                        {faqs.map((faq, index) => (
                            <Faq
                                key={faq.id}
                                index={index}
                                faq={faq}
                                openId={this.state.openId}
                                setOpen={this.setOpen}
                            />
                        ))}
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

const mapStoreToProps = ({ cms }) => ({ cms });

export default compose(connect(mapStoreToProps))(FaqPage);
