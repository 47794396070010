import React from "react";
import "./Footer.css";
import logo192 from "../../assets/img/logo/logo192.png";
import { getCurrentYear } from "../../utils/datetime";
import { NAV_LINKS } from "../../constants/routes";
import { Link } from "react-router-dom";
import ExternalLink from "../links/ExternalLink";
import { EMAIL_CONTACT } from "../../constants/business";

const Footer = () => (
    <footer className="footer">
        <div className="container">
            <div className="row py-5">
                <div className="col-md-4 d-flex flex-column">
                    <h1>NAVIGATION</h1>
                    {NAV_LINKS.filter(
                        ({ isVisibleFooterNav }) => isVisibleFooterNav
                    ).map((link) => (
                        <Link
                            className="footer-link"
                            key={link.display}
                            to={link.to}
                        >
                            {link.display}
                        </Link>
                    ))}
                </div>
                <div className="col-md-4 d-flex flex-column">
                    <h1>SOCIAL MEDIA</h1>
                    <ExternalLink
                        className="footer-link"
                        href="https://www.instagram.com/thewshopclub/"
                    >
                        <i className="fab fa-instagram" /> Instagram
                    </ExternalLink>
                </div>
                <div className="col-md-4">
                    <h1>CONTACT US</h1>
                    <a className="footer-link" href={`mailto:${EMAIL_CONTACT}`}>
                        <i className="fal fa-envelope" /> {EMAIL_CONTACT}
                    </a>
                </div>
            </div>
            <div className="row mt-5">
                <div>&copy; Copyright {getCurrentYear()} The Workshop Club</div>
            </div>
        </div>
        <div
            className="footer-logo"
            style={{ backgroundImage: `url(${logo192})` }}
        />
    </footer>
);

export default Footer;
