import { ACTION_SET_CMS } from "../constants";

const getInitialState = () => {
    return {};
};

const INITIAL_STATE = getInitialState();

const applySetCms = (state, action) => ({
    ...state,
    ...action.data,
});

const cmsReducer = (state = INITIAL_STATE, action) => {
    const handlers = {
        [ACTION_SET_CMS]: applySetCms,
    };
    if (action.type in handlers) {
        return handlers[action.type](state, action);
    } else {
        return state;
    }
};

export default cmsReducer;
