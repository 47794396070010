import * as FullStory from "@fullstory/browser";

export const initFullStory = () => {
    /**
     * https://www.npmjs.com/package/@fullstory/browser
     */
    FullStory.init({
        orgId: "1304CC",
        devMode: process.env.NODE_ENV !== "production",
    });
};
