export const DATA_CATEGORIES = {
    c1: {
        name: "LIFESTYLE",
    },
    c2: {
        name: "DESIGN",
    },
    c3: {
        name: "ART",
    },
    c4: {
        name: "FASHION",
    },
    c5: {
        name: "BUSINESS",
    },
    c6: {
        name: "PERSONAL",
    },
    c7: {
        name: "FOOD",
    },
};
