import React from "react";
import "./WorkshopPage.css";
import Carousel, { Modal, ModalGateway } from "react-images";
import Gallery from "react-photo-gallery";
import { ROUTE_NOT_FOUND } from "../../constants/routes";
import Helmet from "react-helmet";
import { APP_TITLE } from "../../constants/helmet";
import ExternalLink from "../../components/links/ExternalLink";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import {
    getFormattedClassStartDate,
    getFormattedClassStartTime,
    getHumanizedDuration,
    getHumanizedDurationFromMinutes,
} from "../../utils/datetime";
import { getCurrency } from "../../utils/currency";
import {
    DATA_INSTRUCTORS,
    getFormattedInstructorName,
} from "../../constants/v1/instructors";
import { GetClassById } from "../../modules/workshopda/workshopda";
import BookNowButton from "../../components/buttons/BookNowButton";

const getCarousellBgImageCssValue = (url) =>
    `linear-gradient(0deg, rgba(0,0,0,1), rgba(0,0,0,0.1), rgba(0,0,0,0.1), rgba(0,0,0,0.1), rgba(0,0,0,0.1)), url(${url})`;

/**
 * TODO: Reference https://www.work-shop.com.au/event/introduction-brush-lettering-sydney-2/
 */
class WorkshopPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            galleryOpenIndex: 0,
            viewerIsOpen: false,
        };
    }

    componentDidMount() {
        if (this.props.location && this.props.location.state) {
            this.setState(this.props.location.state);
        } else if (
            this.props.match &&
            this.props.match.params &&
            this.props.match.params.workshopId
        ) {
            GetClassById(this.props.match.params.workshopId)
                .then(
                    (workshop) =>
                        new Promise((resolve) =>
                            this.setState(workshop, resolve)
                        )
                )
                .catch((err) => {
                    console.error("WorkshopPage.componentDidMount", err);
                    this.props.history.push(ROUTE_NOT_FOUND);
                });
        }
    }

    openLightBoxGenerator = (event, { index }) =>
        this.setState({ galleryOpenIndex: index, viewerIsOpen: true });
    closeLightBox = () => this.setState({ viewerIsOpen: false });

    getFormattedSeoTitle = () => {
        if (this.state.instructorId in DATA_INSTRUCTORS) {
            const instructor = DATA_INSTRUCTORS[this.state.instructorId];
            return `${this.state.title} - ${getFormattedInstructorName(
                instructor
            )} | ${APP_TITLE}`;
        }
        return "";
    };

    render() {
        const instructor = DATA_INSTRUCTORS[this.state.instructorId];
        return (
            <React.Fragment>
                <Helmet>
                    {!!this.state.title && (
                        <title>{this.getFormattedSeoTitle()}</title>
                    )}
                    {!!this.state.title && (
                        <meta
                            property="og:title"
                            content={this.getFormattedSeoTitle()}
                        />
                    )}
                    {!!this.state.description && (
                        <meta
                            name="description"
                            content={this.state.description}
                        />
                    )}
                    {!!this.state.description && (
                        <meta
                            property="og:description"
                            content={this.state.description}
                        />
                    )}
                    {!!this.state.description && (
                        <meta
                            property="og:description"
                            content={this.state.description}
                        />
                    )}
                    {!!this.state.imgFeaturedJumboDesktop && (
                        <meta
                            property="og:image"
                            content={`${window.location.origin}/${this.state.imgFeaturedJumboDesktop}`}
                        />
                    )}
                </Helmet>
                <div className="jumbotron hero hero-workshop">
                    <Slider
                        infinite
                        dots={false}
                        arrows={false}
                        slidesToShow={1}
                        autoplay={
                            !!this.state.imgWorkshopJumboCarousel &&
                            this.state.imgWorkshopJumboCarousel.length > 1
                        }
                        autoplaySpeed={3000}
                    >
                        {!!this.state.imgWorkshopJumboCarousel &&
                            this.state.imgWorkshopJumboCarousel.map(
                                (url, index) => (
                                    <React.Fragment key={index}>
                                        <div
                                            className="workshop-jumbo-carousel-item"
                                            style={{
                                                backgroundImage:
                                                    getCarousellBgImageCssValue(
                                                        url
                                                    ),
                                            }}
                                        />
                                    </React.Fragment>
                                )
                            )}
                    </Slider>
                    <div className="workshop-hero-fixed">
                        <div className="container">
                            <div className="row h-100">
                                <div className="col-lg-6 d-flex justify-content-center align-items-center flex-column text-center">
                                    <div className="jumbotron-title">
                                        {this.state.title}
                                    </div>
                                    <div className="w-100">
                                        <div className="jumbotron-divider" />
                                    </div>
                                    <p className="jumbotron-description">
                                        {this.state.description}
                                    </p>
                                    <BookNowButton
                                        soldOut={this.state.soldOut}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <section id="workshop-format" className="dark">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5">
                                <h1>{this.state.title}</h1>
                                <p className="fst-italic">
                                    by {getFormattedInstructorName(instructor)}
                                </p>
                            </div>
                            <div className="col-lg-7">
                                <div className="row text-center">
                                    <div className="col-md-2 pb-lg-0 pb-5">
                                        {this.state.date &&
                                            this.state.date.start &&
                                            this.state.date.end && (
                                                <React.Fragment>
                                                    <div className="format-icon">
                                                        <i className="fal fa-calendar-alt" />
                                                    </div>
                                                    <div className="format-description">
                                                        {!!this.state.date &&
                                                            getFormattedClassStartDate(
                                                                this.state.date
                                                                    .start
                                                            )}
                                                        <br />
                                                        {!!this.state.date &&
                                                            getFormattedClassStartTime(
                                                                this.state.date
                                                                    .start
                                                            )}
                                                    </div>
                                                </React.Fragment>
                                            )}
                                    </div>
                                    <div className="col-md-2 pb-lg-0 pb-5">
                                        {this.state.date &&
                                        this.state.date.start &&
                                        this.state.date.end ? (
                                            <React.Fragment>
                                                <div className="format-icon">
                                                    <i className="fal fa-clock" />
                                                </div>
                                                <div className="format-description">
                                                    {!!this.state.date &&
                                                        getHumanizedDuration(
                                                            this.state.date
                                                                .start,
                                                            this.state.date.end
                                                        )}
                                                </div>
                                            </React.Fragment>
                                        ) : (
                                            <React.Fragment>
                                                <div className="format-icon">
                                                    <i className="fal fa-clock" />
                                                </div>
                                                <div className="format-description">
                                                    {!!this.state.date &&
                                                        getHumanizedDurationFromMinutes(
                                                            this.state.date
                                                                .durationMinutes
                                                        )}
                                                </div>
                                            </React.Fragment>
                                        )}
                                    </div>

                                    <div className="col-md-2 pb-lg-0 pb-5">
                                        <div className="format-icon">
                                            <i className="fal fa-users" />
                                        </div>
                                        <div className="format-description">
                                            {this.state.groupSize &&
                                                `${this.state.groupSize.min}${
                                                    this.state.groupSize.min &&
                                                    this.state.groupSize.max
                                                        ? " — "
                                                        : ""
                                                }${
                                                    this.state.groupSize.max
                                                        ? this.state.groupSize
                                                              .max
                                                        : ""
                                                }`}
                                            <br />
                                            Pax
                                        </div>
                                    </div>
                                    <div className="col-md-2 pb-lg-0 pb-5">
                                        <div className="format-icon">
                                            <i className="fal fa-child" />
                                        </div>
                                        <div className="format-description">
                                            {!!this.state.ages &&
                                                `${this.state.ages.min}+`}
                                            <br />
                                            Years
                                        </div>
                                    </div>
                                    <div className="col-md-2 pb-lg-0 pb-5">
                                        <div className="format-icon">
                                            <i className="fal fa-dollar-sign" />
                                        </div>
                                        <div className="format-description">
                                            {!!this.state.price &&
                                                getCurrency(
                                                    this.state.price.amount,
                                                    this.state.price.currency
                                                )}
                                            <br />
                                            {!!this.state.price &&
                                            !!this.state.price.priceType
                                                ? this.state.price.priceType
                                                : "Nett"}
                                        </div>
                                    </div>
                                    <div className="col-md-2 pb-lg-0 pb-5">
                                        <div className="format-icon">
                                            <i className="fal fa-book-reader" />
                                        </div>
                                        <div className="format-description">
                                            {!!this.state.teachingMethods &&
                                                `${
                                                    this.state.teachingMethods
                                                        .online
                                                        ? "Online"
                                                        : ""
                                                }${
                                                    this.state.teachingMethods
                                                        .online &&
                                                    this.state.teachingMethods
                                                        .inPerson
                                                        ? " / "
                                                        : ""
                                                }${
                                                    this.state.teachingMethods
                                                        .inPerson
                                                        ? "In-Person"
                                                        : ""
                                                }`}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="workshop-detail" className="light">
                    <div className="container">
                        <div className="row">
                            {/*TODO: Rich Text Content. Maybe prismic?*/}
                            {!!this.state.details && !!this.state.details.v1 && (
                                <div className="col-lg-6 px-lg-4 pb-lg-0 pb-3">
                                    <h1>CLASS DETAIL</h1>
                                    {this.state.details.v1.content
                                        .split("\n")
                                        .map((content, index) => (
                                            <p key={index}>{content}</p>
                                        ))}
                                    <h3>What You'll Get</h3>
                                    <ul>
                                        {this.state.details.v1.whatYouGet.map(
                                            (wyg) => (
                                                <li key={wyg}>{wyg}</li>
                                            )
                                        )}
                                    </ul>
                                    <h3>What to bring</h3>
                                    <ul>
                                        {this.state.details.v1.whatToBring.map(
                                            (wtb) => (
                                                <li key={wtb}>{wtb}</li>
                                            )
                                        )}
                                    </ul>
                                    <h3>Terms & Conditions</h3>
                                    <ul>
                                        {this.state.details.v1.termsAndConditions.map(
                                            (tnc, i) => (
                                                <li key={i}>{tnc}</li>
                                            )
                                        )}
                                    </ul>
                                    {this.state.details.v1.locations && (
                                        <React.Fragment>
                                            <h3>Location</h3>
                                            <ul>
                                                {this.state.details.v1.locations.map(
                                                    (loc, i) => (
                                                        <li key={i}>{loc}</li>
                                                    )
                                                )}
                                            </ul>
                                        </React.Fragment>
                                    )}
                                </div>
                            )}
                            {!!instructor && (
                                <div className="col-lg-6 px-lg-4">
                                    <div className="instructor">
                                        <div className="row">
                                            <div className="col-md-4 avatar-col">
                                                <div className="avatar-container">
                                                    <div
                                                        className="avatar"
                                                        style={{
                                                            backgroundImage: `url(${instructor.avatar})`,
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-8">
                                                <h2>
                                                    {getFormattedInstructorName(
                                                        instructor
                                                    )}
                                                </h2>
                                                {instructor.biography
                                                    .split("\n")
                                                    .map((p, pIndex) => (
                                                        <p key={pIndex}>{p}</p>
                                                    ))}
                                                {instructor.personalLink && (
                                                    <React.Fragment>
                                                        <i className="fal fa-link" />{" "}
                                                        <ExternalLink
                                                            href={
                                                                instructor.personalLink
                                                            }
                                                        >
                                                            {
                                                                instructor.personalLink
                                                            }
                                                        </ExternalLink>
                                                    </React.Fragment>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </section>
                <section className="light pt-0  d-flex align-items-center justify-content-center">
                    <BookNowButton
                        soldOut={this.state.soldOut}
                        className="btn btn-lg btn-color-invert"
                    />
                </section>
                <section className="light">
                    <div className="container">
                        {!!this.state.imgWorkshopGallery && (
                            <React.Fragment>
                                <Gallery
                                    photos={this.state.imgWorkshopGallery}
                                    onClick={this.openLightBoxGenerator}
                                />

                                <ModalGateway>
                                    {this.state.viewerIsOpen && (
                                        <Modal
                                            allowFullscreen={false}
                                            onClose={this.closeLightBox}
                                        >
                                            <Carousel
                                                currentIndex={
                                                    this.state.galleryOpenIndex
                                                }
                                                views={this.state.imgWorkshopGallery.map(
                                                    (x) => ({
                                                        ...x,
                                                        srcset: x.srcset,
                                                        caption: x.title,
                                                    })
                                                )}
                                            />
                                        </Modal>
                                    )}
                                </ModalGateway>
                            </React.Fragment>
                        )}
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

export default WorkshopPage;
