import React from "react";
import { getAnalytics, logEvent } from "firebase/analytics";

class ExternalLink extends React.Component {
    onClick = () => {
        const { href } = this.props;
        if (!!href) {
            const analytics = getAnalytics();
            logEvent(analytics, "external_link_click", {
                href,
            });
        }
    };

    render() {
        return (
            <a
                rel="noopener noreferrer"
                target="_blank"
                {...this.props}
                onClick={this.onClick}
            >
                {this.props.children}
            </a>
        );
    }
}

export default ExternalLink;
